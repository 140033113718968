const ArrowOut = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M5.11239 15.1644L3.8855 13.9375L12.2084 5.61454H4.93531V3.88538H15.1645V14.1145H13.4353V6.84146L5.11239 15.1644Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { ArrowOut };
