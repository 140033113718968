import cn from 'classnames';
import { ChangeEvent, FocusEvent, FocusEventHandler, useState } from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormResetField,
} from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { CrossIcon, SuccessIcon } from 'src/uikit/icons';
import { valSchema } from 'src/utils/valSchema';

import { InputType, PHONE_VALIDATION_TYPE, ValidationType } from './constants';
import s from './Input.module.scss';

type BaseInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  InputVariant;

interface InputBase {
  label: string;
  name: string;
  errors: FieldErrors;
  register: UseFormRegister<FieldValues>;
  resetField: UseFormResetField<FieldValues>;
  required: boolean;
  className?: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

interface PhoneInput extends InputBase {
  inputType: InputType.Phone;
  validationType?: undefined;
}

interface NumberInput extends InputBase {
  inputType: InputType.Number;
  validationType: ValidationType;
}

interface TextInput extends InputBase {
  inputType: InputType.Text;
  validationType: ValidationType;
}

type InputVariant = PhoneInput | TextInput | NumberInput;

const getSchema = (
  validationType: ValidationType | typeof PHONE_VALIDATION_TYPE,
  required: boolean,
  disabled?: boolean,
) => {
  const schema = valSchema[validationType];
  return {
    ...schema,
    required: !disabled && required ? schema?.required : false,
  };
};

export type InputProps = Omit<
  BaseInputProps,
  'resetField' | 'label' | 'errors'
> & {
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
};

function InputBody({
  name,
  register,
  required,
  disabled,
  inputType,
  validationType,
  onBlur,
  onChange,
  setIsEmpty,
  ...rest
}: InputProps) {
  const [phoneValue, setPhoneValue] = useState('+7 (___) ___-__-__');
  const [isTouched, setIsTouched] = useState(false);

  const {
    onChange: onChangeInput,
    onBlur: onBlurInput,
    ...restOptions
  } = register(name);

  const onChangeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeInput(event);
    onChange && onChange(event);
    setIsEmpty(!Boolean(event.target.value));
  };

  const onBlurInputHandler = (event: FocusEvent<HTMLInputElement>) => {
    onBlurInput(event);
    onBlur && onBlur(event);
    setIsTouched(true);
  };

  if (inputType === InputType.Number) {
    return (
      <input
        className={s.input}
        id={name}
        data-qa="number-input"
        data-click="funnel-form-input"
        type="number"
        onWheel={(e) => (e.target as HTMLElement).blur()}
        onScroll={undefined}
        {...register(
          name,
          getSchema(validationType as ValidationType, required, disabled),
        )}
        disabled={disabled}
        {...rest}
        {...restOptions}
        onChange={onChangeInputHandler}
        onBlur={onBlurInputHandler}
      />
    );
  }

  if (inputType === InputType.Phone) {
    return (
      <ReactInputMask
        className={s.input}
        data-qa="phone-input"
        data-click="funnel-form-input"
        defaultValue={
          isTouched && phoneValue === '+7 (___) ___-__-__' ? phoneValue : ''
        }
        mask="+7 (999) 999-99-99"
        {...register(
          name,
          getSchema(PHONE_VALIDATION_TYPE, required, disabled),
        )}
        onChange={(event) => {
          onChangeInputHandler(event);
          setPhoneValue(event.target.value);
        }}
        onBlur={onBlurInputHandler}
      />
    );
  }

  return (
    <input
      id={name}
      className={s.input}
      disabled={disabled}
      data-qa="input"
      data-click="funnel-form-input"
      {...rest}
      {...restOptions}
      {...register(
        name,
        getSchema(validationType as ValidationType, required, disabled),
      )}
      onChange={onChangeInputHandler}
      onBlur={onBlurInputHandler}
    />
  );
}

export function Input({
  label,
  required,
  validationType,
  name,
  errors,
  register,
  disabled,
  inputType,
  className = '',
  onBlur,
  onChange,
  resetField,
  ...rest
}: BaseInputProps) {
  const { funnelFormInput } = useAnalytics();
  const [isEmpty, setIsEmpty] = useState(true);

  return (
    <div
      className={cn(s.root, className, {
        [s.error]: errors && errors[name],
        [s.empty]: isEmpty,
        [s.notRequired]: !required,
        [s.disabled]: disabled,
      })}
    >
      <div className={s.wrapper} onClick={() => funnelFormInput()}>
        <InputBody
          name={name}
          register={register}
          required={required}
          disabled={disabled}
          inputType={inputType}
          validationType={validationType}
          setIsEmpty={setIsEmpty}
          onBlur={onBlur}
          onChange={onChange}
          {...rest}
        />

        <label className={s.label} htmlFor={name}>
          {label}
        </label>
        <SuccessIcon className={cn(s.icon, s.iconSuccess)} />
        <CrossIcon
          className={cn(s.icon, s.iconCross)}
          onMouseDown={() => {
            if (inputType === InputType.Phone) {
              resetField(name, { defaultValue: '+7 (___) ___-__-__' });
            } else {
              resetField(name);
            }
            setIsEmpty(true);
          }}
        />
      </div>
      {errors && errors[name] && (
        <span className={s.errorText}>
          {errors[name]?.message?.toString() ?? 'Обязательное поле'}
        </span>
      )}
    </div>
  );
}

Input.inputTypes = InputType;
Input.validations = ValidationType;
