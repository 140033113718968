const Star = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M16 8L18.6333 12.3756L23.6085 13.5279L20.2607 17.3844L20.7023 22.4721L16 20.48L11.2977 22.4721L11.7393 17.3844L8.39155 13.5279L13.3667 12.3756L16 8Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Star };
