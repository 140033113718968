import * as Sentry from '@sentry/nextjs';

import { getUtmFromUrl } from './getUtmFromUrl';

export const getOriginalUTM = () => {
  const originalUTM =
    getUtmFromUrl(window.location.href) === ''
      ? window.localStorage.getItem('originalUTM')
      : getUtmFromUrl(window.location.href);

  let decodedOriginalUTM = '-';

  if (originalUTM) {
    try {
      decodedOriginalUTM = decodeURI(originalUTM);
    } catch (e) {
      decodedOriginalUTM = originalUTM;
      Sentry.withScope(function (scope) {
        scope.setTransactionName('UTM');
        Sentry.setTag('utm', `${originalUTM}`);
        scope.setFingerprint(['utm']);
        Sentry.captureException(
          new Error(`Ошибка декодирования UTM - ${originalUTM}`),
          scope,
        );
      });
    }
  }

  return decodedOriginalUTM;
};
