const EvolutionColor = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      className={className}
    >
      <path
        d="M6.875 27.5138L2 1.51379H28L23.125 27.5138H6.875Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <rect x="11.77" y="5.51379" width="6" height="6" fill="#26D07C" />
      <path
        d="M14.2818 20.5138L8.25825 20.5138C7.61543 20.5138 7.22718 19.8027 7.57479 19.2619L10.5866 14.577C10.9064 14.0794 11.6336 14.0794 11.9535 14.577L14.9652 19.2619C15.3129 19.8027 14.9246 20.5138 14.2818 20.5138Z"
        fill="#26D07C"
      />
      <circle cx="19.3398" cy="17.5138" r="3" fill="#26D07C" />
    </svg>
  );
};

export { EvolutionColor };
