import { useEffect } from 'react';

// based on https://usehooks.com/useOnClickOutside

/**
 * hook useOnClickOutside
 */

export const useOnClickOutside = (
  ref: React.MutableRefObject<HTMLElement | null>,
  handler: (event: MouseEvent | TouchEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements

      if (
        !ref ||
        (ref && !ref.current) ||
        (ref && ref.current && ref.current.contains(event.target as Node))
      )
        return;

      handler(event);
    };

    if (process.browser) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }

    return () => {
      if (process.browser) {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      }
    };
  }, [ref, handler]);
};
