const Arrows = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      className={className}
    >
      <path
        d="M10.8247 35.0889L10.8247 26.2642L2 35.0889L10.8247 35.0889Z"
        fill="currentColor"
      />
      <path
        d="M15.52 6.73877C8.05311 6.73877 2 12.7919 2 20.2587C2 24.0972 3.59966 27.5621 6.16871 30.0231C6.44222 30.2851 6.72671 30.5357 7.02143 30.7742"
        stroke="currentColor"
        strokeWidth="2.912"
      />
      <path
        d="M19.1753 4.91112L19.1753 13.7358L28 4.91113L19.1753 4.91112Z"
        fill="currentColor"
      />
      <path
        d="M14.48 33.2612C21.9469 33.2612 28 27.2081 28 19.7413C28 15.9028 26.4003 12.4379 23.8313 9.97688C23.5578 9.71488 23.2733 9.46426 22.9786 9.22577"
        stroke="currentColor"
        strokeWidth="2.912"
      />
    </svg>
  );
};

export { Arrows };
