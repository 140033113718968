import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { DataFromFormInputs, FieldsNamesType } from 'src/types';
import { valSchema } from 'src/utils/valSchema';

import { Checkbox } from '../Checkbox';
import s from './UserAgreement.module.scss';

interface UserAgreementCheckboxesProps {
  agreementName?:
    | 'site_services_consent'
    | 'online_event_consent'
    | 'education_consent';
  checkboxClassName?: string;
  errors: FieldErrors<DataFromFormInputs>;
  fieldsNames?: FieldsNamesType;
  register: UseFormRegister<FieldValues>;
  sendFormFillAnalytics: (fieldName: string) => void;
  theme?: 'base' | 'purple' | 'dark';
  withSubscribeForMailing?: boolean;
  requiredText?: string;
}

const AGREEMENT_SUBSCRIBE_DOCK =
  'https://cdn.cloud.ru/backend/docs/security/consent_advertising.pdf';

const agreementLinks = {
  site_services_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_site.pdf',
  online_event_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_webinar.pdf',
  education_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_education.pdf',
};

export function UserAgreementCheckboxes({
  agreementName = 'site_services_consent',
  errors,
  fieldsNames,
  register,
  sendFormFillAnalytics,
  withSubscribeForMailing = true,
  checkboxClassName,
}: UserAgreementCheckboxesProps) {
  const userAgreementDesc = `Я даю <a target="_blank" href="${agreementLinks[agreementName]}">согласие</a> на обработку моих персональных данных в соответствии с условиями <a target="_blank" href="https://cdn.cloud.ru/backend/docs/security/politic.pdf">политики конфиденциальности</a>`;
  const subscribeForMailingDesc = `Я подтверждаю свое <a target="_blank" href="${AGREEMENT_SUBSCRIBE_DOCK}">согласие</a> на получение рекламных и информационных сообщений в соответствие условиями <a target="_blank" href="https://cdn.cloud.ru/backend/docs/security/politic.pdf">политики конфиденциальности</a> `;

  return (
    <div className={s.root}>
      <Checkbox
        required
        errors={errors}
        name={agreementName}
        register={register}
        requiredText={valSchema['userAgreement'].required.message}
        label={fieldsNames?.userAgreement ?? userAgreementDesc}
        value="true"
        onChange={(event) => {
          if (event.currentTarget.value) {
            sendFormFillAnalytics('checkbox agreement');
          }
        }}
        className={checkboxClassName}
      />
      {withSubscribeForMailing && (
        <Checkbox
          label={fieldsNames?.checkboxSubscribe ?? subscribeForMailingDesc}
          value="true"
          name="subscribeForMailing"
          required={false}
          errors={errors}
          register={register}
          onChange={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('checkbox reklama');
            }
          }}
          className={checkboxClassName}
        />
      )}
    </div>
  );
}
