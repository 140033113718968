const ArrowOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.11233 15.1647L3.88544 13.9378L12.2084 5.61491H4.93525V3.88574H15.1644V14.1149H13.4352V6.84183L5.11233 15.1647Z"
        fill="#26D07C"
      />
    </svg>
  );
};

export { ArrowOutIcon };
