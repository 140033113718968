const Registry = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M6.6665 21.6667H18.3332M6.6665 21.6667V33.3333H18.3332M6.6665 21.6667V10H18.3332V21.6667M18.3332 21.6667V33.3333M18.3332 21.6667H29.9998V33.3333H18.3332"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
      <rect
        x="23.3335"
        y="6.66669"
        width="10"
        height="10"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Registry };
