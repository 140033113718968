import cn from 'classnames';
import React, { useRef } from 'react';
import { useTooltipPosition } from 'src/hooks/useTooltipPosition';

import { TooltipContentPropsType } from '../../types';
import s from './TooltipContent.module.scss';

const formatString = (count: number, str?: string) => {
  if (!str || str?.length < count) return str;
  return `${str.slice(0, count)}...`;
};

export function TooltipContent({
  className,
  controlElement,
  customContent,
  desc,
  isFixed,
  theme,
  title,
  variant,
}: TooltipContentPropsType) {
  const elRef = useRef<HTMLDivElement>(null);

  useTooltipPosition({
    tooltipElement: elRef.current,
    controlElement: controlElement,
    variant: variant,
    isFixed,
  });

  const formatedTitle = formatString(20, title);
  const formatedDesc = formatString(500, desc);

  if (!(customContent || title || desc)) return null;

  return (
    <div className={cn(s.root, s[theme], { [s.fixed]: isFixed })} ref={elRef}>
      <div className={cn(s.wrapper, className)}>
        {customContent ? (
          customContent
        ) : (
          <>
            {formatedTitle && <span className={s.title}> {formatedTitle}</span>}
            {formatedDesc && (
              <span
                className={s.desc}
                dangerouslySetInnerHTML={{ __html: formatedDesc }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
