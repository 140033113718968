const ChevronLeftIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.15942 18.1597L0 10.0002L8.15942 1.84082L9.73552 3.4169L3.15219 10.0002L9.73552 16.5836L8.15942 18.1597Z"
        fill="#222222"
      />
    </svg>
  );
};

export { ChevronLeftIcon };
