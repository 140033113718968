const Cross = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <circle
        cx="7"
        cy="7"
        r="6"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M9.71533 4.39221L4.21533 9.89221M4.28448 4.39221L9.78448 9.89221"
        stroke="white"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export { Cross };
