import cn from 'classnames';

import s from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  white?: boolean;
  className?: string;
  loaderClassName?: string;
}

export function LoadingSpinner({
  white,
  className = '',
  loaderClassName = '',
}: LoadingSpinnerProps) {
  return (
    <div className={cn(s.root, className)}>
      <div className={cn(s.loader, loaderClassName, { [s.white]: white })} />
    </div>
  );
}
