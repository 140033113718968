const Evolution = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      className={className}
    >
      <path
        d="M7.08154 27.5138L2.20654 1.51379H28.2065L23.3315 27.5138H7.08154Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect x="11.9766" y="5.51379" width="6" height="6" fill="currentColor" />
      <path
        d="M14.4883 20.5138L8.46479 20.5138C7.82197 20.5138 7.43372 19.8027 7.78133 19.2619L10.7931 14.577C11.1129 14.0794 11.8402 14.0794 12.16 14.577L15.1718 19.2619C15.5194 19.8027 15.1312 20.5138 14.4883 20.5138Z"
        fill="currentColor"
      />
      <circle cx="19.5464" cy="17.5138" r="3" fill="currentColor" />
    </svg>
  );
};

export { Evolution };
