const ArrowUp = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <path d="M1 8L7 2L13 8" stroke="#222222" strokeWidth="1.6" />
      <path d="M7 2L7 14" stroke="#222222" strokeWidth="1.6" />
    </svg>
  );
};

export { ArrowUp };
