import { PositionType } from './types';

export const isServer = typeof window === 'undefined';

export enum Variant {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum Theme {
  White = 'white',
  Graphite = 'graphite',
}

export const DEFAULT_POSITION: PositionType = { top: 0, left: 0 };
