const Trino = ({ className }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 19.5125V5M25 19.5806L32.4362 6.66667M29.1029 25.8333C29.1029 29.9755 24.9988 33.3333 19.9362 33.3333C14.8736 33.3333 10.7695 29.9755 10.7695 25.8333C10.7695 21.6912 14.8736 18.3333 19.9362 18.3333C24.9988 18.3333 29.1029 21.6912 29.1029 25.8333Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M6.66699 30L6.66699 23.3333M33.3337 30V23.3333"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Trino };
