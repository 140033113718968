import cn from 'classnames';
import { type LinkProps } from 'next/link';
import { NextLink } from 'src/components/NextLink';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { ClickAnalyticsType } from 'src/types';

import s from './Link.module.scss';

interface Default extends LinkTypeBase {
  isDefault?: true;
  leftIcon?: string | React.ReactNode;
  rightIcon?: string | React.ReactNode;
}

interface NotDefault extends LinkTypeBase {
  isDefault?: false;
  leftIcon?: undefined;
  rightIcon?: undefined;
}

interface LinkTypeBase {
  analyticsOptions?: ClickAnalyticsType;
  children?: React.ReactNode;
  className?: string;
  color?: 'graphite' | 'white' | 'additional';
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onClick?(): void;
  tabIndex?: number;
  target?: '_blank' | '_self';
}

type LinkType = LinkProps &
  (Default | NotDefault) &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

function Icon({ icon }: { icon?: string | React.ReactNode }) {
  if (typeof icon === 'string') {
    return <img src={icon} alt="" className={s.icon} />;
  }

  return icon;
}

type DefaultLinkType = {
  children?: React.ReactNode;
  leftIcon?: string | React.ReactNode;
  rightIcon?: string | React.ReactNode;
};

function DefaultLink({ children, leftIcon, rightIcon }: DefaultLinkType) {
  return (
    <>
      <Icon icon={leftIcon} />
      <span>{children}</span>
      <Icon icon={rightIcon} />
    </>
  );
}

export function Link({
  analyticsOptions,
  children,
  className = '',
  color = 'graphite',
  isDefault = false,
  isDisabled,
  isReadOnly = false,
  leftIcon,
  onClick,
  rightIcon,
  target = '_self',
  ...rest
}: LinkType) {
  const { clickAnalytics } = useAnalytics();

  const onLinkClick = () => {
    onClick && onClick();

    if (analyticsOptions) {
      clickAnalytics(analyticsOptions);
    }
  };

  return (
    <NextLink
      className={cn(
        {
          [s.root]: isDefault,
          [s[color]]: isDefault,
          [s.withIcon]: leftIcon || rightIcon,
          [s.disabled]: isDisabled,
          [s.readOnly]: isReadOnly,
        },
        className,
      )}
      onClick={onLinkClick}
      target={target}
      {...rest}
      data-click="allclicks"
    >
      {isDefault ? (
        <DefaultLink leftIcon={leftIcon} rightIcon={rightIcon}>
          {children}
        </DefaultLink>
      ) : (
        children
      )}
    </NextLink>
  );
}
