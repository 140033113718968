import { Lang, Locale } from 'src/types';

export const localeToLang = (locale: Locale): Lang => {
  switch (locale) {
    case 'catchAll':
    case 'ru':
      return 'ru';
    default:
      return 'ru';
  }
};
