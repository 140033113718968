const CrossIcon = ({
  className = '',
  onMouseDown,
}: {
  className?: string;
  onMouseDown?: () => void;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      onMouseDown={onMouseDown}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M6.06274 15.1594L4.84082 13.9375L8.77832 9.99999L4.84082 6.06249L6.06274 4.84058L10.0002 8.77808L13.9377 4.84058L15.1597 6.06249L11.2222 9.99999L15.1597 13.9375L13.9377 15.1594L10.0002 11.2219L6.06274 15.1594Z"
        fill="#222222"
      />
    </svg>
  );
};

export { CrossIcon };
