const Telegram = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="3.75" fill="#25A3E1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.492 22.4487C18.6805 21.1723 28.5642 14.2053 26.2369 15.3675L19.9577 18.7957C16.2428 20.9239 16.5126 20.5847 17.2987 23.1177C17.3728 23.3625 17.6819 24.7185 17.9253 24.9937C18.362 25.4874 18.4866 22.4839 18.492 22.4487ZM15.0136 21.0714C15.3689 21.2038 15.2918 21.1589 15.4125 21.547C15.679 22.4039 15.9577 23.194 16.229 24.0444C17.149 26.9276 17.0357 26.9438 18.3448 25.9394C20.5053 24.2817 20.3051 24.1193 21.545 24.9447L25.1211 27.4037C26.1383 28.1277 26.1918 26.6611 26.3664 25.8755L29.2325 12.9867C29.2958 12.736 29.2585 12.5814 29.1599 12.4945C28.9528 12.3122 28.4759 12.4283 28.0931 12.5754L12.8546 18.1165C8.28838 19.7358 12.0951 19.9865 15.0136 21.0714Z"
      fill="white"
    />
  </svg>
);

export { Telegram };
