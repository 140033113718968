const ElasticCloudServerGPUColor = ({
  className = '',
}: {
  className?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="1.5"
        width="26"
        height="25"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path d="M19 27.5L19 30.5" stroke="#222222" strokeWidth="2.8" />
      <path d="M5 27.5L5 30.5" stroke="#222222" strokeWidth="2.8" />
      <path d="M12 27.5L12 30.5" stroke="#222222" strokeWidth="2.8" />
      <path d="M25 27.5L25 30.5" stroke="#222222" strokeWidth="2.8" />
      <path d="M14 20L18 14H12L16.5 8" stroke="#26D07C" strokeWidth="2.8" />
    </svg>
  );
};

export { ElasticCloudServerGPUColor };
