import { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

import { isServer } from '../../constants';

type PortalPropsType = {
  children: React.ReactNode;
  isOpened: boolean;
};

export const Portal: FunctionComponent<PortalPropsType> = ({
  isOpened,
  children,
}) => {
  if (!isOpened || isServer) {
    return null;
  }

  return ReactDOM.createPortal(children, document.body);
};
