const Question = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="7" cy="7" r="6" stroke="black" strokeWidth="1.6" />
      <path
        d="M5.82324 9.74C5.82324 9.2 6.11324 8.86 6.70324 8.86C7.24324 8.86 7.58324 9.22 7.58324 9.74C7.58324 10.27 7.27324 10.63 6.70324 10.63C6.16324 10.63 5.82324 10.26 5.82324 9.74ZM6.03324 8.09C6.03324 6.37 7.37324 6.49 7.37324 5.43C7.37324 5 7.14324 4.77 6.70324 4.77C6.11324 4.77 5.56324 5.02 5.19324 5.32V3.71C5.57324 3.53 6.16324 3.38 6.96324 3.38C8.32324 3.38 8.97324 3.97 8.97324 5.18C8.97324 7.15 7.37324 6.96 7.37324 8.09H6.03324Z"
        fill="black"
      />
    </svg>
  );
};

export { Question };
