import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { DEFAULT_POSITION, Variant } from 'src/uikit/Tooltip/constants';
import { PositionType } from 'src/uikit/Tooltip/types';

import { useWindowWidth } from './useWindowWidth';

interface useTooltipPositionParams {
  tooltipElement: HTMLElement | null;
  controlElement: HTMLElement | null;
  variant?: Variant;
  isFixed?: boolean;
}

const MARGING = 10;
const HALF_WIDTH_ARROW = 4;

export const useTooltipPosition = ({
  controlElement,
  tooltipElement,
  variant,
  isFixed = false,
}: useTooltipPositionParams) => {
  const { windowWidth } = useWindowWidth();
  const [variantValid, setVariantValid] = useState<Variant | undefined>(
    variant,
  );
  const [position, setPosition] = useState<PositionType>(DEFAULT_POSITION);
  const [positionArrow, setPositionArrow] =
    useState<PositionType>(DEFAULT_POSITION);
  const [controlRect, setControlRect] = useState<DOMRectReadOnly | null>(null);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const scrollY = isFixed ? 0 : window.scrollY;
  const { scrollX } = window;

  useLayoutEffect(() => {
    if (tooltipElement) {
      tooltipElement.style.minWidth = '0';
      setTooltipWidth(tooltipElement.offsetWidth);
      setTooltipHeight(tooltipElement.offsetHeight);
    }
    setClientWidth(document.documentElement.clientWidth);
    setClientHeight(document.documentElement.clientHeight);
  }, [
    windowWidth,
    tooltipElement,
    tooltipElement?.offsetWidth,
    tooltipElement?.offsetHeight,
  ]);

  useLayoutEffect(() => {
    if (controlElement) {
      setControlRect(controlElement.getBoundingClientRect());
    }
  }, [controlElement, windowWidth]);

  useEffect(() => {
    if (tooltipElement) {
      tooltipElement.style.top = `${position.top}px`;
      tooltipElement.style.left = `${position.left}px`;
      tooltipElement.style.opacity = '1';
      tooltipElement.style.minWidth = `${tooltipWidth}px`;
      tooltipElement.style.setProperty('--topArrow', `${positionArrow.top}px`);
      tooltipElement.style.setProperty(
        '--leftArrow',
        `${positionArrow.left}px`,
      );
    }
  }, [position, positionArrow]);

  const checkValidTooltipPosition = useCallback(
    ({ left, top }: PositionType): boolean => {
      return (
        top > 0 &&
        left > 0 &&
        top + tooltipHeight < clientHeight + scrollY &&
        left + tooltipWidth < clientWidth &&
        (!isFixed || top > scrollY) // for fixed position
      );
    },
    [tooltipHeight, clientHeight, scrollY, tooltipWidth, clientWidth, isFixed],
  );

  const getHorizontalPosition = useCallback(
    ({ left }: { left: number }): PositionType | null => {
      if (!controlRect) return null;

      const topPositions = [
        controlRect.top + controlRect.height / 2 - tooltipHeight / 2 + scrollY,
        controlRect.top + scrollY,
        controlRect.bottom - tooltipHeight + scrollY,
      ];

      const validTopPosition = topPositions.find((top) =>
        checkValidTooltipPosition({ top, left }),
      );

      if (!validTopPosition) return null;
      return { top: validTopPosition, left };
    },
    [checkValidTooltipPosition, controlRect],
  );

  const getVerticalPosition = useCallback(
    ({ top }: { top: number }): PositionType | null => {
      if (!controlRect) return null;
      const leftPositions = [
        controlRect.left + controlRect.width / 2 - tooltipWidth / 2 + scrollX,
        controlRect.left + scrollX,
        controlRect.left + controlRect.width + scrollX - tooltipWidth,
        10,
      ];

      const validTopPosition = leftPositions.find((left) =>
        checkValidTooltipPosition({ top, left }),
      );

      if (!validTopPosition) return null;
      return { top, left: validTopPosition };
    },
    [checkValidTooltipPosition, controlRect, scrollX],
  );

  const getLeftPositionForArrow = useCallback((): number => {
    if (!controlRect) return 0;
    if (tooltipWidth <= controlRect.width) {
      return tooltipWidth / 2 - HALF_WIDTH_ARROW;
    }
    return (
      controlRect.left -
      position.left +
      controlRect.width / 2 -
      HALF_WIDTH_ARROW
    );
  }, [controlRect, position.left, tooltipWidth]);

  const getTopPositionForArrow = useCallback((): number => {
    if (!controlRect) return 0;

    if (tooltipHeight <= controlRect.height) {
      return tooltipHeight / 2 - HALF_WIDTH_ARROW;
    }

    return (
      controlRect.top -
      position.top +
      scrollY +
      controlRect.height / 2 -
      HALF_WIDTH_ARROW
    );
  }, [controlRect, position.top, scrollY, tooltipHeight]);

  const getPosition = useCallback(
    (
      isAutoPosition?: boolean,
    ): { position: PositionType; variantTooltip?: Variant } => {
      if (!controlRect)
        return { position: DEFAULT_POSITION, variantTooltip: variant };

      if (variant === Variant.Top || isAutoPosition) {
        const top = controlRect.top - tooltipHeight - MARGING + scrollY;
        const position = getVerticalPosition({ top });

        if (position) {
          return { position, variantTooltip: Variant.Top };
        }
      }

      if (variant === Variant.Bottom || isAutoPosition) {
        const top = controlRect.top + controlRect.height + MARGING + scrollY;
        const position = getVerticalPosition({ top });

        if (position) {
          return { position, variantTooltip: Variant.Bottom };
        }
      }

      if (variant === Variant.Left || isAutoPosition) {
        const left = controlRect.left - tooltipWidth - MARGING + scrollX;
        const position = getHorizontalPosition({ left });

        if (position) {
          return { position, variantTooltip: Variant.Left };
        }
      }

      if (variant === Variant.Right || isAutoPosition) {
        const left = controlRect.left + controlRect.width + MARGING;
        const position = getHorizontalPosition({ left });

        if (position) {
          return { position, variantTooltip: Variant.Right };
        }
      }

      if (!isAutoPosition) {
        return getPosition(true);
      }

      return { position: DEFAULT_POSITION, variantTooltip: variant };
    },
    [getHorizontalPosition, getVerticalPosition, variant],
  );

  const getArrowPosition = useCallback((): PositionType => {
    let top = 0;
    let left = 0;

    switch (variantValid) {
      case Variant.Left:
        left = tooltipWidth - HALF_WIDTH_ARROW;
        top = getTopPositionForArrow();
        return { left, top };

      case Variant.Right:
        left = -HALF_WIDTH_ARROW;
        top = getTopPositionForArrow();
        return { left, top };

      case Variant.Top:
        left = getLeftPositionForArrow();
        top = tooltipHeight - HALF_WIDTH_ARROW;
        return { left, top };

      case Variant.Bottom:
        left = getLeftPositionForArrow();
        top = -HALF_WIDTH_ARROW;
        return { left, top };
    }
    return DEFAULT_POSITION;
  }, [getLeftPositionForArrow, getTopPositionForArrow, variantValid]);

  useEffect(() => {
    if (controlElement && tooltipElement) {
      const { position, variantTooltip } = getPosition(!variant);
      setPosition(position);
      setVariantValid(variantTooltip);
    }
  }, [controlElement, getPosition, tooltipElement, variant]);

  useEffect(() => {
    if (controlElement && tooltipElement) {
      setPositionArrow(getArrowPosition());
    }
  }, [getArrowPosition]);
};
