const SuccessIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M8.22908 14.222L4.54883 10.5209L5.75489 9.31486L8.22908 11.7682L14.2449 5.77319L15.451 7.00009L8.22908 14.222Z"
        fill="#D3D3D3"
      />
    </svg>
  );
};

export { SuccessIcon };
