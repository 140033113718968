const CloudBackupAndRecovery = ({ className }: { className?: string }) => {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.424 222.248L163.176 221.702V222.261H66.8699L66.8031 222.261C66.718 222.262 66.6319 222.262 66.5447 222.262C43.5672 222.262 20.7186 201.359 20.7186 177.814C20.7186 156.507 39.5185 136.935 60.942 133.782L74.871 131.733L76.7677 117.768C79.8718 94.913 100.023 76.7379 125 76.7379C149.976 76.7379 170.127 94.9129 173.231 117.768L175.128 131.733L189.057 133.782C210.481 136.934 229.281 156.506 229.281 177.814C229.281 201.359 206.433 222.262 183.455 222.262C183.11 222.262 182.766 222.257 182.424 222.248ZM183.848 240.999C184.239 240.996 184.63 240.989 185.021 240.978C216.774 240.084 248 212.146 248 177.814C248 145.924 221.059 119.551 191.779 115.243C187.39 82.929 159.165 58 125 58C90.8342 58 62.6086 82.9291 58.2197 115.244C28.9401 119.552 2 145.924 2 177.814C2 212.71 34.2606 241 66.5447 241L66.6743 241C66.7618 241 66.8493 240.999 66.9368 240.999H181.894V240.978C182.283 240.989 182.673 240.996 183.063 240.999C183.129 240.999 183.195 240.999 183.26 241C183.325 241 183.39 241 183.455 241C183.586 241 183.717 241 183.848 240.999Z"
        fill="#26D07C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.138 117.768L223.77 129.766C224.692 130.518 225.596 131.293 226.481 132.092L237.984 133.782C259.434 136.934 278.258 156.506 278.258 177.814C278.258 201.359 255.381 222.262 232.375 222.262C232.029 222.262 231.685 222.257 231.342 222.248L227.969 222.152C216.204 233.415 200.539 240.534 184.743 240.978C184.373 240.989 184.002 240.996 183.631 240.999H230.812V240.978C231.201 240.989 231.591 240.996 231.982 240.999C232.076 240.999 232.169 241 232.263 241C232.3 241 232.337 241 232.375 241C232.506 241 232.637 241 232.768 240.999C233.159 240.996 233.551 240.989 233.942 240.978C265.735 240.084 297 212.146 297 177.814C297 145.924 270.026 119.551 240.709 115.243C236.315 82.929 208.054 58 173.846 58C165.166 58 156.868 59.6053 149.246 62.5292C157.721 65.7804 165.361 70.6621 171.763 76.7801C172.454 76.752 173.148 76.7379 173.846 76.7379C198.853 76.7379 219.03 94.9129 222.138 117.768ZM181.613 240.978C181.981 240.989 182.351 240.996 182.721 240.999H181.613V240.978ZM115 240.999H115.636L115.596 240.999L115.448 241L115.319 241C115.212 241 115.106 241 115 240.999Z"
        fill="#CCCBCA"
      />
      <path
        d="M176 176C176 181.523 171.523 186 166 186C160.477 186 156 181.523 156 176C156 170.477 160.477 166 166 166C171.523 166 176 170.477 176 176Z"
        fill="#343F48"
      />
      <path
        d="M64 176C64 162.745 74.7452 152 88 152H167C180.255 152 191 162.745 191 176C191 189.255 180.255 200 167 200H88C74.7452 200 64 189.255 64 176Z"
        stroke="#343F48"
        strokeWidth="12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150 182H86V170H150V182Z"
        fill="#343F48"
      />
    </svg>
  );
};

export { CloudBackupAndRecovery };
