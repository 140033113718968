const PostgreSQL = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M18.1761 15.4711C17.8791 18.3178 16.3641 24.0113 12.6806 24.0113C8.07632 24.0113 7.61954 11.0993 9.80147 8.51328C13.2345 4.44457 29.6829 5.8163 31.3138 13.3033C31.8584 15.8035 31.1721 21.531 24.3399 27.0561C23.0032 28.1371 21.9737 30.1932 23.0032 32.0317C24.3399 34.4191 27.0876 34.0368 29.167 32.4773"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
      <path
        d="M25.2768 24.5157L28.7371 28.0846M24.7824 16.67C24.7824 17.1302 24.4093 17.5033 23.9491 17.5033C23.4888 17.5033 23.1157 17.1302 23.1157 16.67C23.1157 16.2098 23.4888 15.8367 23.9491 15.8367C24.4093 15.8367 24.7824 16.2098 24.7824 16.67Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { PostgreSQL };
