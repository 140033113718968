const Filter = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path d="M17 6L3 6" stroke="black" strokeWidth="1.6" />
      <path d="M14 10H6" stroke="black" strokeWidth="1.6" />
      <path d="M12 14H8" stroke="black" strokeWidth="1.6" />
    </svg>
  );
};

export { Filter };
