const Serverless = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M18.3332 25L12.4998 21.6667L6.6665 25V31.6667L12.4998 35L18.3332 31.6667V25Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M5 20.2941C5 16.0708 8.35786 12.6471 12.5 12.6471C12.5 10.098 14 5 20 5C25.4377 5 27.5 9.77941 27.5 12.6471C30.3125 12.6471 35 14.5588 35 20.2941C35 26.0294 30.3125 27.9412 27.5 27.9412H23.75"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Serverless };
