export enum Variant {
  Default = 'default',
  Primary = 'primary',
  Outline = 'outline',
}

export enum TextColor {
  Graphite = 'graphite',
  Green = 'green',
  White = 'white',
}

export enum Appearance {
  'fluorescent-yellow-1' = 'fluorescent-yellow-1',
  'fluorescent-yellow-2' = 'fluorescent-yellow-2',
  'fluorescent-yellow-3' = 'fluorescent-yellow-3',
  'fluorescent-yellow-4' = 'fluorescent-yellow-4',
  'fluorescent-yellow-5' = 'fluorescent-yellow-5',
  'aquamarine-1' = 'aquamarine-1',
  'aquamarine-2' = 'aquamarine-2',
  'aquamarine-3' = 'aquamarine-3',
  'aquamarine-4' = 'aquamarine-4',
  'aquamarine-5' = 'aquamarine-5',
  'aquamarine-6' = 'aquamarine-6',
  'ultramarine-1' = 'ultramarine-1',
  'ultramarine-2' = 'ultramarine-2',
  'ultramarine-3' = 'ultramarine-3',
  'ultramarine-4' = 'ultramarine-4',
  'ultramarine-5' = 'ultramarine-5',
  'ultramarine-6' = 'ultramarine-6',
  'purple-1' = 'purple-1',
  'purple-2' = 'purple-2',
  'purple-3' = 'purple-3',
  'purple-4' = 'purple-4',
  'purple-5' = 'purple-5',
  'magneta-1' = 'magneta-1',
  'magneta-2' = 'magneta-2',
  'magneta-3' = 'magneta-3',
  'magneta-4' = 'magneta-4',
  'magneta-5' = 'magneta-5',
  'carrot-1' = 'carrot-1',
  'carrot-2' = 'carrot-2',
  'carrot-3' = 'carrot-3',
  'carrot-4' = 'carrot-4',
  'carrot-5' = 'carrot-5',
  'coral-1' = 'coral-1',
  'coral-2' = 'coral-2',
  'coral-3' = 'coral-3',
  'coral-4' = 'coral-4',
  'coral-5' = 'coral-5',
  'green-main-100' = 'green-main-100',
  'green-main-90' = 'green-main-90',
  'green-main-80' = 'green-main-80',
  'green-main-70' = 'green-main-70',
  'green-main-60' = 'green-main-60',
  'green-main-50' = 'green-main-50',
  'graphite-100' = 'graphite-100',
  'graphite-90' = 'graphite-90',
  'graphite-80' = 'graphite-80',
  'graphite-70' = 'graphite-70',
  'graphite-60' = 'graphite-60',
  'graphite-50' = 'graphite-50',
  'graphite-10' = 'graphite-10',
  'gray-100' = 'gray-100',
  'gray-90' = 'gray-90',
  'gray-80' = 'gray-80',
  'gray-70' = 'gray-70',
  'gray-60' = 'gray-60',
  'gray-0' = 'gray-0',
}
