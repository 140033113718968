import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { getOriginalUTM } from 'src/utils/getOriginalUTM';

interface MetaDataForForm {
  retainURL: string;
  originalUTM: string;
  gaClientID: string;
  yaClientID: string;
}

export const useGetUserMetaData = (): MetaDataForForm => {
  const [formData, setFormData] = useState({
    retainURL: '',
    originalUTM: '',
    gaClientID: '',
    yaClientID: '',
  });

  useEffect(() => {
    if (!window) return;
    //
    const cookies = parseCookies();
    //
    let gaClientID = '-';
    const cookiesGa = cookies?._ga ? cookies._ga : '';
    const cookiesGaArray = cookiesGa.split('.');
    const clientId = cookiesGaArray?.[2];
    const timestamp = cookiesGaArray?.[3];
    if (clientId && timestamp) gaClientID = clientId + '.' + timestamp;
    //
    const yaClientID = cookies?._ym_uid ? cookies._ym_uid : '-';
    //
    const retainURL = window.location.href;
    //

    const decodedOriginalUTM = getOriginalUTM();

    //
    const data = {
      gaClientID,
      yaClientID,
      retainURL,
      originalUTM: decodedOriginalUTM,
    };
    setFormData(data);
  }, []);

  return formData;
};
