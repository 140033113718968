const ChevronRightIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      className={className}
    >
      <path
        d="M4.71558 5.99999L0.778076 2.06249L1.99999 0.840576L7.15941 5.99999L1.99999 11.1594L0.778076 9.93749L4.71558 5.99999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { ChevronRightIcon };
