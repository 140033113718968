const ArenadataCloudColor = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      className={className}
    >
      <ellipse
        cx="18.3423"
        cy="13.8178"
        rx="12.5"
        ry="6"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0791 27.0108C31.0791 25.2474 29.7005 23.8178 28.0002 23.8178C26.2998 23.8178 24.9212 25.2474 24.9212 27.0108C23.2209 27.0108 21.8423 28.4403 21.8423 30.2038C21.8423 31.9672 23.2209 33.3968 24.9212 33.3968H31.0791C32.7795 33.3968 34.1581 31.9672 34.1581 30.2038C34.1581 28.4403 32.7795 27.0108 31.0791 27.0108Z"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
      <path
        d="M17.8423 25.8178C11.2149 25.8178 5.84229 23.1315 5.84229 19.8178"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
      <path
        d="M18.3423 31.8178C11.4387 31.8178 5.84229 29.1315 5.84229 25.8178V13.8178M30.8423 13.8178V21.3178"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M28.6755 10.3179L25.1752 13.8179C23.3638 13.0223 21.0411 12.5444 18.5085 12.5444C15.9759 12.5444 13.6531 13.0223 11.8418 13.8179L8.00879 9.98462"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M18.5088 12.8179L18.5088 7.81787"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { ArenadataCloudColor };
