const ManagedKubernetesColor = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="none"
      className={className}
    >
      <path
        d="M10.6152 9.51379V15.5138L15.9998 18.5138L21.3845 15.5138V9.51379L15.9998 6.51379L10.6152 9.51379Z"
        stroke="#26D07C"
        strokeWidth="2.8"
      />
      <path
        d="M7.38462 1.51379L2 4.56935V20.4582L7.38461 23.5138M24.6154 1.51379L30 4.56935V20.4582L24.6154 23.5138"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { ManagedKubernetesColor };
