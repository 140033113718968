export function InfoIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.2545 13.7479H8.75369V8.74793H11.2545V13.7479Z" />
      <path d="M11.2508 6.24793C11.2508 6.93829 10.691 7.49793 10.0004 7.49793C9.30983 7.49793 8.75 6.93829 8.75 6.24793C8.75 5.55758 9.30983 4.99793 10.0004 4.99793C10.691 4.99793 11.2508 5.55758 11.2508 6.24793Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM18.2353 10C18.2353 14.1421 14.1421 18.2353 10 18.2353C5.85786 18.2353 1.76471 14.1421 1.76471 10C1.76471 5.85786 5.85786 1.76471 10 1.76471C14.1421 1.76471 18.2353 5.85786 18.2353 10Z"
      />
    </svg>
  );
}
