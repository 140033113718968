import cn from 'classnames';
import { useId } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { CheckIcon, TooltipIcon } from 'src/uikit/icons';
import { Tooltip } from 'src/uikit/Tooltip';
import { valSchema } from 'src/utils/valSchema';

import s from './Checkbox.module.scss';

interface CheckboxBase {
  name: string;
  value: string;
  label: string | JSX.Element;
  counter?: number;
  disabled?: boolean;
  readOnly?: boolean;
  isChecked?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
  errors: FieldErrors;
  register: UseFormRegister<FieldValues>;
  className?: string;
  errorClassName?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
}

interface CheckboxNotRequired extends CheckboxBase {
  required: false;
  requiredText?: undefined;
}

interface CheckboxRequired extends CheckboxBase {
  required: true;
  requiredText?: string;
}

type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  (CheckboxNotRequired | CheckboxRequired);

export function Checkbox({
  name,
  errors,
  required,
  disabled,
  readOnly,
  label,
  value,
  isChecked,
  tooltipTitle,
  tooltipDescription,
  counter,
  register,
  onChange,
  className,
  fullWidth,
  requiredText,
  errorClassName,
  ...rest
}: CheckboxProps) {
  const { funnelFormInput, clickAnalytics } = useAnalytics();
  const checkBoxId = useId();

  const { onChange: onChangeRegister, ...restOptions } = {
    ...register(name, {
      required: required ? requiredText || valSchema.default?.required : false,
    }),
  };

  const handleClick = (title: string | JSX.Element) => {
    clickAnalytics({
      action: 'click',
      clickZone: 'body',
      clickElement: 'buttonclick',
      clickContent: `checkbox-${title}`,
      uniqueId: `checkbox-${title}`,
      transitionType: 'inside-link',
    });
  };

  return (
    <div
      className={cn(s.wrapper, {
        [s.error]: errors && errors[name],
      })}
    >
      <div
        className={cn(s.checkbox, className, {
          [s.disabled]: disabled,
          [s.readonly]: readOnly,
          [s.fullWidth]: fullWidth,
        })}
      >
        <label htmlFor={checkBoxId} className={s.label}>
          <input
            id={checkBoxId}
            type="checkbox"
            data-qa="checkbox"
            data-click="funnel-form-input"
            value={value}
            disabled={disabled || readOnly}
            readOnly={readOnly}
            checked={isChecked}
            {...rest}
            {...restOptions}
            onClick={() => handleClick(label)}
            onChange={(event) => {
              onChangeRegister(event);
              onChange && onChange(event);
              funnelFormInput();
            }}
          />
          <span className={s.checkboxIconWrapper}>
            <CheckIcon className={s.checkboxIcon} />
          </span>
          <span
            className={s.text}
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {counter && <span className={s.counter}>{counter}</span>}

          {(tooltipDescription || tooltipTitle) && (
            <Tooltip
              title={tooltipTitle || ''}
              desc={tooltipDescription}
              variant={Tooltip.variants.Bottom}
            >
              <span className={s.tooltipIconWrapper}>
                <TooltipIcon className={s.tooltipIcon} />
              </span>
            </Tooltip>
          )}
        </label>
      </div>

      {errors && errors[name] && (
        <div className={cn(s.error, errorClassName)}>
          {errors[name]?.message?.toString()}
        </div>
      )}
    </div>
  );
}
