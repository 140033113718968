const CheckFill = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <circle
        cx="7"
        cy="7"
        r="6"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M9.66846 4.96326L6.31037 8.32135L4.33136 6.34234"
        stroke="white"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export { CheckFill };
