const ManagedKubernetes = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      className={className}
    >
      <path
        d="M10.6157 10.2831V16.7446L16.0003 19.9754L21.385 16.7446V10.2831L16.0003 7.05231L10.6157 10.2831Z"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path
        d="M7.38462 1.66766L2 4.95826V22.0694L7.38461 25.36M24.6154 1.66766L30 4.95826V22.0694L24.6154 25.36"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
    </svg>
  );
};

export { ManagedKubernetes };
