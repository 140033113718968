import { useEffect } from 'react';

import { TryFormProps, useAnalytics } from './useAnalytics';

export const useTryFormErrorsCheck = (
  errors: any,
  isSubmitting: boolean,
  formType: TryFormProps['formType'],
) => {
  const { tryFormAnalytics } = useAnalytics();
  useEffect(() => {
    if (Object.keys(errors).length && formType) {
      if (isSubmitting) {
        tryFormAnalytics({
          formType: formType,
          action: 'Поля',
          fields: Object.keys(errors),
        });
      }
    }
  }, [errors, formType, isSubmitting, tryFormAnalytics]);
};
