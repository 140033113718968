const ArrowLeftIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_24158_23126)">
        <path
          d="M15.8334 10.1458H3.00007M8.08386 5L2.95886 10.1458L8.08386 15.2916"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24158_23126">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ArrowLeftIcon };
