const Migration = ({ className }: { className?: string }) => {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.867 56.309H46.1327C42.9388 56.309 40.3495 58.8922 40.3495 62.0787V199.921C40.3495 203.108 42.9387 205.691 46.1327 205.691H222.867C226.061 205.691 228.65 203.108 228.65 199.921V62.0787C228.65 58.8922 226.061 56.309 222.867 56.309ZM46.1327 39C33.3569 39 23 49.3327 23 62.0787V199.921C23 212.667 33.3568 223 46.1327 223H222.867C235.643 223 246 212.667 246 199.921V62.0787C246 49.3327 235.643 39 222.867 39H46.1327Z"
        fill="#343F48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.867 56.309H46.1327C42.9388 56.309 40.3495 58.8922 40.3495 62.0787V199.921C40.3495 203.108 42.9387 205.691 46.1327 205.691H222.867C226.061 205.691 228.65 203.108 228.65 199.921V62.0787C228.65 58.8922 226.061 56.309 222.867 56.309ZM46.1327 39C33.3569 39 23 49.3327 23 62.0787V199.921C23 212.667 33.3568 223 46.1327 223H222.867C235.643 223 246 212.667 246 199.921V62.0787C246 49.3327 235.643 39 222.867 39H46.1327Z"
        fill="#343F48"
      />
      <path d="M61 243H208V260H61V243Z" fill="#343F48" />
      <path
        d="M69 78C69 82.4183 65.4183 86 61 86C56.5817 86 53 82.4183 53 78C53 73.5817 56.5817 70 61 70C65.4183 70 69 73.5817 69 78Z"
        fill="#C4C4C4"
      />
      <path
        d="M69 102.5C69 106.642 65.4183 110 61 110C56.5817 110 53 106.642 53 102.5C53 98.3579 56.5817 95 61 95C65.4183 95 69 98.3579 69 102.5Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.317 222.985C230.676 222.995 231.035 223 231.396 223C253.706 223 276 203.522 276 179.494C276 157.537 257.382 139.379 237.148 136.413C234.114 114.164 214.609 97 190.999 97C167.389 97 147.884 114.164 144.851 136.413C124.617 139.38 106 157.537 106 179.494C106 203.522 128.294 223 150.604 223C150.635 223 150.667 223 150.698 223H230.317V222.985Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.948 205.164L230.809 205.668C231.003 205.673 231.199 205.676 231.396 205.676C245.07 205.676 258.631 193.027 258.631 179.494C258.631 167.321 247.566 155.45 234.622 153.552L221.697 151.658L219.937 138.747C218.095 125.243 206.082 114.324 190.999 114.324C175.916 114.324 163.903 125.244 162.062 138.747L160.301 151.658L147.377 153.552C134.433 155.45 123.369 167.321 123.369 179.494C123.369 193.027 136.93 205.676 150.604 205.676L150.654 205.676L150.676 205.676H212.948V205.164ZM232.478 222.985C232.148 222.994 231.819 222.999 231.49 223C231.458 223 231.427 223 231.396 223C231.365 223 231.333 223 231.302 223C230.973 222.999 230.645 222.994 230.317 222.985V223H150.698L150.604 223C128.294 223 106 203.522 106 179.494C106 157.537 124.617 139.38 144.851 136.413C147.884 114.164 167.389 97 190.999 97C214.609 97 234.114 114.164 237.148 136.413C257.382 139.379 276 157.537 276 179.494C276 203.133 254.421 222.369 232.478 222.985Z"
        fill="#26D07C"
      />
      <path
        d="M185.881 176.869L185.881 157.742C185.881 152.599 179.674 150.01 176.019 153.63L162.553 166.968C158.88 170.606 161.456 176.869 166.627 176.869L185.881 176.869Z"
        fill="#343F48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169 193V173H186V193H169Z"
        fill="#343F48"
      />
      <path
        d="M213.154 168.04C218.392 168.04 220.936 174.443 217.127 178.038L204.5 189.955C200.808 193.439 194.739 190.821 194.739 185.745L194.739 168.04L213.154 168.04Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195 150V171H212V150H195Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export { Migration };
