const CodDraas = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="28"
      viewBox="0 0 40 28"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9228 25.8207V25.8217H15.0305H15.0263L15.022 25.8217C15.0142 25.8217 15.0063 25.8218 14.9983 25.8218C11.5289 25.8218 8.07388 22.7451 8.07388 19.1822C8.07388 15.948 10.9296 13.0711 14.1375 12.6057L15.6026 12.3932L15.8021 10.9453C16.2717 7.53693 19.3115 4.85122 23.0524 4.85122C26.7933 4.85122 29.8331 7.53692 30.3027 10.9452L30.5022 12.3932L31.9674 12.6057C35.1753 13.071 38.0311 15.948 38.0311 19.1822C38.0311 22.7451 34.5761 25.8218 31.1067 25.8218C31.0699 25.8218 31.0331 25.8214 30.9965 25.8207H28.9228ZM30.8917 27.7617V27.7617V27.7646H28.9228H15.0305L15.0129 27.7647L14.9983 27.7647C10.55 27.7647 6.10498 23.9222 6.10498 19.1822C6.10498 14.8507 9.8169 11.2687 13.8512 10.6835C14.4559 6.29432 18.345 2.9083 23.0524 2.9083C27.7598 2.9083 31.6489 6.29431 32.2537 10.6835C36.288 11.2686 40 14.8507 40 19.1822C40 23.8456 35.6975 27.6402 31.3224 27.7617C31.2613 27.7634 31.2001 27.7644 31.139 27.7646C31.1335 27.7646 31.128 27.7646 31.1226 27.7647C31.1173 27.7647 31.112 27.7647 31.1067 27.7647C31.096 27.7647 31.0852 27.7647 31.0745 27.7646C31.0134 27.7644 30.9525 27.7634 30.8917 27.7617Z"
        fill="#26D07C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7334 17.2C17.7334 16.7582 18.0628 16.4 18.4691 16.4L27.6644 16.4C28.0707 16.4 28.4001 16.7582 28.4001 17.2C28.4001 17.6418 28.0707 18 27.6644 18L18.4691 18C18.0628 18 17.7334 17.6418 17.7334 17.2Z"
        fill="#343F48"
      />
      <path
        d="M18.6667 15.4667C18.4233 15.4667 18.1798 15.3699 18.0093 15.1924C17.912 15.0956 17.847 14.9988 17.8064 14.8859C17.7577 14.7729 17.7334 14.6519 17.7334 14.5309C17.7334 14.4099 17.7577 14.289 17.8064 14.176C17.8551 14.055 17.9201 13.9502 18.0093 13.8695C18.0905 13.7888 18.196 13.7162 18.3096 13.6759C18.4801 13.6033 18.6667 13.5791 18.8534 13.6194C18.9102 13.6275 18.967 13.6517 19.0319 13.6759C19.0806 13.692 19.1375 13.7243 19.1943 13.7566C19.243 13.7888 19.2917 13.8292 19.3322 13.8695C19.4134 13.9663 19.4864 14.055 19.5351 14.176C19.5838 14.289 19.6001 14.4099 19.6001 14.5309C19.6001 14.781 19.5027 15.0149 19.3241 15.1924C19.243 15.2731 19.1375 15.3457 19.0238 15.386C18.9102 15.4263 18.7966 15.4667 18.6667 15.4667Z"
        fill="#343F48"
      />
      <path
        d="M18.6667 20.6667C18.4233 20.6667 18.1798 20.5699 18.0093 20.3924C17.912 20.2956 17.847 20.1988 17.8064 20.0859C17.7577 19.9729 17.7334 19.8519 17.7334 19.7309C17.7334 19.6099 17.7577 19.4889 17.8064 19.376C17.8551 19.255 17.9201 19.1502 18.0093 19.0695C18.0905 18.9888 18.196 18.9162 18.3096 18.8759C18.4801 18.8033 18.6667 18.7791 18.8534 18.8194C18.9102 18.8275 18.967 18.8517 19.0319 18.8759C19.0806 18.892 19.1375 18.9243 19.1943 18.9566C19.243 18.9888 19.2917 19.0292 19.3322 19.0695C19.4134 19.1663 19.4864 19.255 19.5351 19.376C19.5838 19.4889 19.6001 19.6099 19.6001 19.7309C19.6001 19.981 19.5027 20.2149 19.3241 20.3924C19.243 20.4731 19.1375 20.5457 19.0238 20.586C18.9102 20.6263 18.7966 20.6667 18.6667 20.6667Z"
        fill="#343F48"
      />
      <path
        d="M21.0668 15.4667C20.8233 15.4667 20.5798 15.3699 20.4094 15.1924C20.312 15.0956 20.247 14.9988 20.2065 14.8859C20.1578 14.7729 20.1334 14.6519 20.1334 14.5309C20.1334 14.4099 20.1578 14.289 20.2065 14.176C20.2552 14.055 20.3201 13.9502 20.4094 13.8695C20.4905 13.7888 20.596 13.7162 20.7096 13.6759C20.8801 13.6033 21.0667 13.5791 21.2534 13.6194C21.3102 13.6275 21.367 13.6517 21.432 13.6759C21.4807 13.692 21.5375 13.7243 21.5943 13.7566C21.643 13.7888 21.6917 13.8292 21.7323 13.8695C21.8134 13.9663 21.8865 14.055 21.9352 14.176C21.9839 14.289 22.0001 14.4099 22.0001 14.5309C22.0001 14.781 21.9027 15.0149 21.7241 15.1924C21.643 15.2731 21.5375 15.3457 21.4239 15.386C21.3102 15.4263 21.1966 15.4667 21.0668 15.4667Z"
        fill="#343F48"
      />
      <path
        d="M21.0668 20.6667C20.8233 20.6667 20.5798 20.5699 20.4094 20.3924C20.312 20.2956 20.247 20.1988 20.2065 20.0859C20.1578 19.9729 20.1334 19.8519 20.1334 19.7309C20.1334 19.6099 20.1578 19.4889 20.2065 19.376C20.2552 19.255 20.3201 19.1502 20.4094 19.0695C20.4905 18.9888 20.596 18.9162 20.7096 18.8759C20.8801 18.8033 21.0667 18.7791 21.2534 18.8194C21.3102 18.8275 21.367 18.8517 21.432 18.8759C21.4807 18.892 21.5375 18.9243 21.5943 18.9566C21.643 18.9888 21.6917 19.0292 21.7323 19.0695C21.8134 19.1663 21.8865 19.255 21.9352 19.376C21.9839 19.4889 22.0001 19.6099 22.0001 19.7309C22.0001 19.981 21.9027 20.2149 21.7241 20.3924C21.643 20.4731 21.5375 20.5457 21.4239 20.586C21.3102 20.6263 21.1966 20.6667 21.0668 20.6667Z"
        fill="#343F48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7334 22.2667C17.7334 21.8248 18.0628 21.4667 18.4691 21.4667L27.6644 21.4667C28.0707 21.4667 28.4001 21.8248 28.4001 22.2667C28.4001 22.7085 28.0707 23.0667 27.6644 23.0667L18.4691 23.0667C18.0628 23.0667 17.7334 22.7085 17.7334 22.2667Z"
        fill="#343F48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7334 12C17.7334 11.5582 18.0628 11.2 18.4691 11.2L27.6644 11.2C28.0707 11.2 28.4001 11.5582 28.4001 12C28.4001 12.4418 28.0707 12.8 27.6644 12.8L18.4691 12.8C18.0628 12.8 17.7334 12.4418 17.7334 12Z"
        fill="#343F48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8491 3.15809C21.5492 2.99481 22.2802 2.9083 23.0322 2.9083C23.2507 2.9083 23.4674 2.9156 23.6821 2.92997C21.9905 1.20746 19.5988 0.133331 16.9474 0.133331C12.24 0.133331 8.35096 3.51935 7.74619 7.90851C3.71192 8.49373 0 12.0758 0 16.4072C0 21.0546 4.27311 24.8393 8.63272 24.9853C7.97501 24.3268 7.41489 23.5723 6.99033 22.7471C4.26341 21.8913 1.9689 19.3108 1.9689 16.4072C1.9689 13.1731 4.8246 10.2961 8.03254 9.83078L9.49763 9.61825L9.69714 8.17028C10.1668 4.76196 13.2065 2.07625 16.9474 2.07625C18.3874 2.07625 19.7235 2.47418 20.8491 3.15809Z"
        fill="#343F48"
      />
    </svg>
  );
};

export { CodDraas };
