const YouTube = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="3.75" fill="#ED3925" />
    <path
      d="M27.633 20.0661C28.0934 20.2965 28.0934 20.9535 27.633 21.1839L15.5922 27.2088C15.1766 27.4167 14.6875 27.1145 14.6875 26.6498L14.6875 14.6001C14.6875 14.1355 15.1766 13.8333 15.5922 14.0412L27.633 20.0661Z"
      fill="white"
    />
  </svg>
);

export { YouTube };
