import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { ClickAnalyticsType } from 'src/types';

import { Link } from '../Link';
import s from './Button.module.scss';
import { Color, Size, Target, Variant } from './constants';

interface ButtonBaseProps {
  size?: Size;
  isDisabled?: boolean;
  className?: string;
  children: React.ReactNode;
  analyticsOptions: ClickAnalyticsType;
  onlyIcon?: boolean;
  fullWidth?: boolean;
}

interface ButtonWithLinkProps extends ButtonBaseProps {
  link: string;
  target?: Target;
  type?: undefined;
  onClick?: () => void;
}

interface ButtonWithoutLinkProps extends ButtonBaseProps {
  link?: undefined;
  type?: 'submit' | 'reset' | 'button';
  target?: undefined;
  onClick: () => void;
}

type ButtonClickProps = ButtonWithLinkProps | ButtonWithoutLinkProps;

interface ButtonPrimaryProps extends ButtonBaseProps {
  variant?: Variant.Primary;
  color?: Color.Green | Color.Yellow;
}

interface ButtonSecondaryProps extends ButtonBaseProps {
  variant?: Variant.Outline;
  color?: Color;
}

interface ButtonTertiaryProps extends ButtonBaseProps {
  variant?: Variant.Tertiary;
  color?: Color.Green | Color.Graphite;
}

type ButtonAppearanceProps =
  | ButtonPrimaryProps
  | ButtonSecondaryProps
  | ButtonTertiaryProps;

type ButtonProps = ButtonBaseProps & ButtonAppearanceProps & ButtonClickProps;

export function Button({
  variant = Variant.Primary,
  size = Size.Standard,
  color,
  fullWidth = false,
  isDisabled = false,
  onClick,
  children,
  type,
  link,
  onlyIcon,
  className,
  target = Target.Self,
  analyticsOptions,
  ...rest
}: ButtonProps) {
  const { clickAnalytics } = useAnalytics();

  const onButtonClick = () => {
    if (!isDisabled)
      try {
        clickAnalytics(analyticsOptions);

        onClick && onClick();
      } catch {
        // noop
      }
  };

  const getColorClass = () => {
    switch (variant) {
      case Variant.Outline:
        return s[color || Color.Graphite];
      case Variant.Tertiary:
        return s[color || Color.Graphite];
      case Variant.Primary:
      default:
        return s[color || Color.Green];
    }
  };

  const classNames = cn(
    s.root,
    className,
    s[size],
    s[variant],
    getColorClass(),
    {
      [s.disabled]: isDisabled,
      [s.onlyIcon]: onlyIcon,
      [s.fullWidth]: fullWidth,
    },
  );

  if (link) {
    return (
      <Link
        className={classNames}
        href={link}
        analyticsOptions={analyticsOptions}
        id="temporary"
        target={target}
        onClick={onButtonClick}
        {...rest}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      onClick={onButtonClick}
      className={classNames}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.variants = Variant;
Button.sizes = Size;
Button.colors = Color;
Button.targets = Target;
