const SearchIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <circle
        cx="5.84038"
        cy="5.84038"
        r="4.84038"
        stroke="black"
        strokeWidth="1.6"
      />
      <path
        d="M9.16797 9.16815L12.9999 13.0001"
        stroke="black"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export { SearchIcon };
