const ElasticCloudServer = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 18.9608C34 14.4997 30.4955 10.8105 26.6867 10.2078C26.1158 5.68734 22.4442 2.2 17.9999 2.2C13.5557 2.2 9.88405 5.68735 9.31313 10.2078C5.5044 10.8105 2 14.4997 2 18.9608C2 23.8426 6.1965 27.8 10.3961 27.8L10.4129 27.8C10.4243 27.7999 10.4357 27.7999 10.4471 27.7998H22.966C23.8367 27.7998 24.7076 27.7967 25.5786 27.7999C29.7827 27.8159 34 23.8524 34 18.9608Z"
        stroke="black"
        strokeWidth="2.8"
      />
      <rect
        x="10"
        y="16.6"
        width="16"
        height="4.8"
        stroke="black"
        strokeWidth="2.8"
      />
      <path
        d="M14.7998 16.6L14.7998 21.4"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { ElasticCloudServer };
