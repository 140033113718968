const EvoCompute = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.96375 18.1085L17.0404 14.0228L17.0767 5.91431L10.0363 1.89147L2.95967 5.97717L2.92338 14.0857L9.96375 18.1085Z"
        stroke="#222222"
        strokeWidth="1.4"
      />
      <path
        d="M6.94983 8.13319L10.042 6.17677L13.1342 8.13319V12.046L10.042 14.0025"
        stroke="#222222"
        strokeWidth="1.4"
      />
      <path
        d="M6.95094 8.01012L6.95094 12.0395L9.79235 13.8444"
        stroke="#26D07C"
        strokeWidth="1.4"
      />
      <path
        d="M9.96545 18.0474L10 9.99989L3.2949 6.00734"
        stroke="#222222"
        strokeWidth="1.4"
      />
    </svg>
  );
};

export { EvoCompute };
