import cn from 'classnames';

import { Question } from '../icons';
import { Tooltip } from '../Tooltip';
import { Variant as VariantTooltip } from '../Tooltip/constants';
import { Appearance, TextColor, Variant } from './constants';
import s from './Tag.module.scss';

function Icon({
  icon,
  classNameIcon,
}: {
  icon?: string | React.ReactNode;
  classNameIcon?: string;
}) {
  if (typeof icon === 'string') {
    return <img src={icon} alt="tooltip icon" className={classNameIcon} />;
  }

  return icon;
}

interface TagBase {
  children: React.ReactNode;
  className?: string;
  textColor?: TextColor;
  tooltip?: {
    text: string;
    icon?: string | React.ReactNode;
    position?: VariantTooltip;
    classNameTooltipIcon?: string;
  };
}

interface TagDefaultProps extends TagBase {
  variant?: Variant.Default;
  appearance?: undefined;
}

interface TagPrimaryAndOutlineProps extends TagBase {
  variant?: Variant.Primary | Variant.Outline;
  appearance?: Appearance;
}

type TagProps = TagDefaultProps | TagPrimaryAndOutlineProps;

export function Tag({
  children,
  className,
  variant = Variant.Default,
  textColor = TextColor.Graphite,
  appearance,
  tooltip,
}: TagProps) {
  const getAppearanceClass = () => {
    switch (variant) {
      case Variant.Primary:
        return s[appearance || Appearance['green-main-100']];
      case Variant.Outline:
        return s[appearance || Appearance['graphite-10']];
      case Variant.Default:
      default:
        return '';
    }
  };

  return (
    <div
      className={cn(
        s.root,
        s[variant],
        s[textColor],
        getAppearanceClass(),
        className,
      )}
    >
      {children}

      {tooltip && (
        <Tooltip
          desc={tooltip.text}
          theme={Tooltip.themes.Graphite}
          variant={tooltip.position}
          className={s.tooltipContent}
          isFixed
        >
          <div className={s.tooltipWrapper}>
            {tooltip.icon ? (
              <Icon
                icon={tooltip.icon}
                classNameIcon={tooltip.classNameTooltipIcon}
              />
            ) : (
              <Question />
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

Tag.variants = Variant;
Tag.textColors = TextColor;
Tag.appearances = Appearance;
Tag.tooltipPosition = VariantTooltip;
