const CheckIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.65058 7.80089L9.96874 1.50263L8.46608 0L3.65058 4.7831L1.50263 2.66758L0 4.17021L3.65058 7.80089Z"
        fill="white"
      />
    </svg>
  );
};

export { CheckIcon };
