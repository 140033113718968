import { type LinkProps } from 'next/link';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { ClickAnalyticsType } from 'src/types';

import { NextLink } from '../NextLink';

interface WithButton extends LinkTypeBase {
  withButton: true;
  analyticsOptions?: undefined;
  id?: undefined;
}

interface NotWithButton extends LinkTypeBase {
  analyticsOptions: ClickAnalyticsType;
  id: string;
  withButton?: false;
}

interface LinkTypeBase {
  children?: React.ReactNode;
  onClick?(): void;
  className?: string;
  tabIndex?: number;
  target?: string;
}

type LinkType = LinkProps &
  (WithButton | NotWithButton) &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function Link({
  children,
  id,
  analyticsOptions,
  onClick,
  className = '',
  withButton,
  ...rest
}: LinkType) {
  const { clickAnalytics } = useAnalytics();

  const onLinkClick = () => {
    onClick && onClick();

    if (!withButton && analyticsOptions) {
      clickAnalytics(analyticsOptions);
    }
  };

  return (
    <NextLink
      className={className}
      onClick={onLinkClick}
      id={id}
      {...rest}
      data-click="allclicks"
    >
      {children}
    </NextLink>
  );
}
