const Done = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      className={className}
    >
      <path d="M1.5 4L6.5 9L14.5 1" stroke="currentColor" strokeWidth="1.6" />
    </svg>
  );
};

export { Done };
