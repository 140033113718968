const ServerCustom = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M12.1356 10.0833C11.5174 10.0833 10.9931 10.2997 10.5625 10.7324C10.1319 11.1651 9.91667 11.6906 9.91667 12.3088C9.91667 12.927 10.133 13.4513 10.5658 13.8819C10.9985 14.3125 11.524 14.5277 12.1421 14.5277C12.7603 14.5277 13.2847 14.3114 13.7153 13.8786C14.1458 13.4459 14.3611 12.9205 14.3611 12.3023C14.3611 11.6841 14.1447 11.1597 13.712 10.7291C13.2793 10.2986 12.7538 10.0833 12.1356 10.0833ZM12.1356 27.1389C11.5174 27.1389 10.9931 27.3553 10.5625 27.788C10.1319 28.2207 9.91667 28.7462 9.91667 29.3644C9.91667 29.9825 10.133 30.5069 10.5658 30.9375C10.9985 31.368 11.524 31.5833 12.1421 31.5833C12.7603 31.5833 13.2847 31.367 13.7153 30.9342C14.1458 30.5015 14.3611 29.976 14.3611 29.3578C14.3611 28.7396 14.1447 28.2153 13.712 27.7847C13.2793 27.3542 12.7538 27.1389 12.1356 27.1389ZM5 19.6111V5.02777H35V19.6111H5ZM7.77775 7.80552V16.8333H32.2222V7.80552H7.77775ZM5 36.6666V22.0556H35V36.6666H5ZM7.77775 24.8333V33.8889H32.2222V24.8333H7.77775Z"
        fill="black"
      />
    </svg>
  );
};

export { ServerCustom };
