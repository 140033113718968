export function QuestionIcon({ variant }: { variant?: 'white' | 'dark' }) {
  switch (variant) {
    case 'white':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="c-tool-tip-question-icon_white"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.20277 6.00063C4.40236 4.08966 6.01823 2.6 7.98203 2.6H8.58203C10.3493 2.6 11.782 4.03269 11.782 5.8C11.782 7.30991 10.7363 8.57558 9.32954 8.91222C9.13331 8.95918 8.98203 9.12669 8.98203 9.32846V10C8.98203 10.2209 8.80295 10.4 8.58203 10.4H7.38203C7.16112 10.4 6.98203 10.2209 6.98203 10V8.32941C6.98203 7.5952 7.57723 7 8.31144 7H8.58203C9.24477 7 9.78203 6.46274 9.78203 5.8C9.78203 5.13726 9.24477 4.6 8.58203 4.6H7.98203C7.12429 4.6 6.40667 5.19996 6.22593 6.00312C6.17743 6.21865 6.00294 6.4 5.78203 6.4H4.58203C4.36112 6.4 4.17982 6.22035 4.20277 6.00063ZM7.00002 13.2C7.00002 13.4209 7.17911 13.6 7.40002 13.6H8.60002C8.82094 13.6 9.00002 13.4209 9.00002 13.2V12C9.00002 11.7791 8.82094 11.6 8.60002 11.6H7.40002C7.17911 11.6 7.00002 11.7791 7.00002 12V13.2Z"
            fill="currentColor"
            fillOpacity="0.24"
          />
        </svg>
      );
    case 'dark':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="c-tool-tip-question-icon c-tool-tip-question-icon_dark"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.20277 6.00063C4.40236 4.08966 6.01823 2.6 7.98203 2.6H8.58203C10.3493 2.6 11.782 4.03269 11.782 5.8C11.782 7.30991 10.7363 8.57558 9.32954 8.91222C9.13331 8.95918 8.98203 9.12669 8.98203 9.32846V10C8.98203 10.2209 8.80295 10.4 8.58203 10.4H7.38203C7.16112 10.4 6.98203 10.2209 6.98203 10V8.32941C6.98203 7.5952 7.57723 7 8.31144 7H8.58203C9.24477 7 9.78203 6.46274 9.78203 5.8C9.78203 5.13726 9.24477 4.6 8.58203 4.6H7.98203C7.12429 4.6 6.40667 5.19996 6.22593 6.00312C6.17743 6.21865 6.00294 6.4 5.78203 6.4H4.58203C4.36112 6.4 4.17982 6.22035 4.20277 6.00063ZM7.00002 13.2C7.00002 13.4209 7.17911 13.6 7.40002 13.6H8.60002C8.82094 13.6 9.00002 13.4209 9.00002 13.2V12C9.00002 11.7791 8.82094 11.6 8.60002 11.6H7.40002C7.17911 11.6 7.00002 11.7791 7.00002 12V13.2Z"
            fill="currentColor"
          />
        </svg>
      );

    default:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="c-tool-tip-question-icon_default"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.20277 6.00063C4.40236 4.08966 6.01823 2.6 7.98203 2.6H8.58203C10.3493 2.6 11.782 4.03269 11.782 5.8C11.782 7.30991 10.7363 8.57558 9.32954 8.91222C9.13331 8.95918 8.98203 9.12669 8.98203 9.32846V10C8.98203 10.2209 8.80295 10.4 8.58203 10.4H7.38203C7.16112 10.4 6.98203 10.2209 6.98203 10V8.32941C6.98203 7.5952 7.57723 7 8.31144 7H8.58203C9.24477 7 9.78203 6.46274 9.78203 5.8C9.78203 5.13726 9.24477 4.6 8.58203 4.6H7.98203C7.12429 4.6 6.40667 5.19996 6.22593 6.00312C6.17743 6.21865 6.00294 6.4 5.78203 6.4H4.58203C4.36112 6.4 4.17982 6.22035 4.20277 6.00063ZM7.00002 13.2C7.00002 13.4209 7.17911 13.6 7.40002 13.6H8.60002C8.82094 13.6 9.00002 13.4209 9.00002 13.2V12C9.00002 11.7791 8.82094 11.6 8.60002 11.6H7.40002C7.17911 11.6 7.00002 11.7791 7.00002 12V13.2Z"
            fill="currentColor"
          />
        </svg>
      );
  }
}
