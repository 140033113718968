export enum Variant {
  Primary = 'primary',
  Outline = 'outline',
  Tertiary = 'tertiary',
}

export enum Size {
  Big = 'big',
  Standard = 'standard',
}

export enum Color {
  Graphite = 'graphite',
  Green = 'green',
  Yellow = 'yellow',
}

export enum Target {
  Blank = '_blank',
  Self = '_self',
}
