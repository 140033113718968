const Marketplace = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M16.4023 9.29852L12 6.89288L7.59766 9.29852"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M16 19V10L12.0099 12.2121L8 10V19"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M4 7.5V16.5L12 21L20 16.5V7.5L12 3L4 7.5Z"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export { Marketplace };
