const VM = ({ className }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 7V5.6H5.6V7H7ZM33 7H34.4V5.6H33V7ZM33 33V34.4H34.4V33H33ZM7 33H5.6V34.4H7V33ZM9.35207 14.6368C9.15148 13.8901 8.38352 13.4473 7.6368 13.6479C6.89007 13.8485 6.44734 14.6165 6.64793 15.3632L9.35207 14.6368ZM10.9549 26L9.60284 26.3632C9.76714 26.9748 10.3216 27.4 10.9549 27.4V26ZM13.045 26V27.4C13.6784 27.4 14.2328 26.9748 14.3971 26.3632L13.045 26ZM17.3521 15.3632C17.5527 14.6165 17.1099 13.8485 16.3632 13.6479C15.6165 13.4473 14.8485 13.8901 14.6479 14.6368L17.3521 15.3632ZM21 15V13.6C20.6287 13.6 20.2726 13.7475 20.0101 14.0101C19.7475 14.2726 19.6 14.6287 19.6 15H21ZM19.6 26C19.6 26.7732 20.2268 27.4 21 27.4C21.7732 27.4 22.4 26.7732 22.4 26H19.6ZM22.5714 15L23.8829 14.51C23.6784 13.9628 23.1556 13.6 22.5714 13.6V15ZM25.7143 23.4115L24.4028 23.9015C24.6073 24.4488 25.1301 24.8115 25.7143 24.8115V23.4115ZM27.2857 23.4115V24.8115C27.8699 24.8115 28.3927 24.4488 28.5972 23.9015L27.2857 23.4115ZM30.4286 15V13.6C29.8444 13.6 29.3216 13.9628 29.1171 14.51L30.4286 15ZM32 15H33.4C33.4 14.6287 33.2525 14.2726 32.99 14.0101C32.7274 13.7475 32.3713 13.6 32 13.6V15ZM30.6 26C30.6 26.7732 31.2268 27.4 32 27.4C32.7732 27.4 33.4 26.7732 33.4 26H30.6ZM7 8.4H33V5.6H7V8.4ZM33 31.6H7V34.4H33V31.6ZM8.4 11V7H5.6V11H8.4ZM8.4 33V29H5.6V33H8.4ZM31.6 7V11H34.4V7H31.6ZM31.6 29V33H34.4V29H31.6ZM6.64793 15.3632L9.60284 26.3632L12.307 25.6368L9.35207 14.6368L6.64793 15.3632ZM10.9549 27.4H13.045V24.6H10.9549V27.4ZM14.3971 26.3632L17.3521 15.3632L14.6479 14.6368L11.693 25.6368L14.3971 26.3632ZM19.6 15L19.6 26H22.4L22.4 15H19.6ZM21 16.4H22.5714V13.6H21V16.4ZM21.26 15.49L24.4028 23.9015L27.0257 22.9215L23.8829 14.51L21.26 15.49ZM25.7143 24.8115H27.2857V22.0115H25.7143V24.8115ZM28.5972 23.9015L31.74 15.49L29.1171 14.51L25.9743 22.9215L28.5972 23.9015ZM30.4286 16.4L32 16.4V13.6L30.4286 13.6V16.4ZM30.6 15L30.6 26H33.4L33.4 15L30.6 15Z"
        fill="#222222"
      />
    </svg>
  );
};

export { VM };
