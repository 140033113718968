const TooltipIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M9.17523 14.0747H10.8247V8.99996H9.17523V14.0747ZM9.99557 7.59961C10.2351 7.59961 10.4373 7.5186 10.6022 7.35659C10.7671 7.19459 10.8496 6.99384 10.8496 6.75436C10.8496 6.51486 10.7686 6.31264 10.6066 6.14771C10.4446 5.98278 10.2438 5.90032 10.0044 5.90032C9.76486 5.90032 9.56264 5.98132 9.39771 6.14334C9.23278 6.30534 9.15032 6.50608 9.15032 6.74557C9.15032 6.98507 9.23132 7.18728 9.39334 7.35221C9.55534 7.51714 9.75608 7.59961 9.99557 7.59961ZM10.0057 18.1694C8.87809 18.1694 7.81822 17.9567 6.82611 17.5315C5.834 17.1062 4.966 16.5217 4.22213 15.7778C3.47825 15.0339 2.89369 14.1662 2.46844 13.1747C2.04319 12.1831 1.83057 11.1217 1.83057 9.99042C1.83057 8.8591 2.04319 7.80086 2.46844 6.81569C2.89369 5.83053 3.47825 4.966 4.22213 4.22213C4.966 3.47825 5.83371 2.89369 6.82523 2.46844C7.81677 2.04319 8.8782 1.83057 10.0095 1.83057C11.1408 1.83057 12.1991 2.04319 13.1842 2.46844C14.1694 2.89369 15.0339 3.47825 15.7778 4.22213C16.5217 4.966 17.1062 5.83209 17.5315 6.82038C17.9567 7.80866 18.1694 8.8666 18.1694 9.99421C18.1694 11.1218 17.9567 12.1817 17.5315 13.1738C17.1062 14.1659 16.5217 15.0339 15.7778 15.7778C15.0339 16.5217 14.1678 17.1062 13.1795 17.5315C12.1913 17.9567 11.1333 18.1694 10.0057 18.1694ZM9.99996 16.4402C11.7922 16.4402 13.3137 15.8149 14.5643 14.5643C15.8149 13.3137 16.4402 11.7922 16.4402 9.99996C16.4402 8.2077 15.8149 6.68626 14.5643 5.43565C13.3137 4.18505 11.7922 3.55975 9.99996 3.55975C8.2077 3.55975 6.68626 4.18505 5.43565 5.43565C4.18505 6.68626 3.55975 8.2077 3.55975 9.99996C3.55975 11.7922 4.18505 13.3137 5.43565 14.5643C6.68626 15.8149 8.2077 16.4402 9.99996 16.4402Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { TooltipIcon };
