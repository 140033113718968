import React, { useEffect, useRef, useState } from 'react';
import { useTimeout } from 'src/hooks/useTimeout';

import { Portal, TooltipContent } from './components';
import { isServer, Theme, Variant } from './constants';
import { TooltipProps } from './types';

export function Tooltip({
  children,
  className,
  customContent,
  desc,
  isFixed,
  theme = Theme.White,
  title,
  variant,
  hoverDelayOpen = 0,
  hoverDelayClose = 0,
}: TooltipProps) {
  const [isChildrenMouseOver, setIsChildrenMouseOver] = useState(false);

  const childrenRef = useRef<HTMLElement>(null);

  const handleMouseOver = () => setIsChildrenMouseOver(true);
  const handleMouseLeave = () => {
    if (!isServer) {
      setIsChildrenMouseOver(false);
    }
  };

  const { set: setMouseOverTimeout, clear: clearMouseOverTimeout } = useTimeout(
    handleMouseOver,
    hoverDelayOpen,
  );
  const { set: setMouseLeaveTimeout, clear: clearMouseLeaveTimeout } =
    useTimeout(handleMouseLeave, hoverDelayClose);

  const onMouseOverChildren = () => {
    clearMouseLeaveTimeout();
    setMouseOverTimeout();
  };

  const onMouseLeaveChildren = () => {
    clearMouseOverTimeout();
    setMouseLeaveTimeout();
  };

  useEffect(() => {
    const el = childrenRef?.current;

    if (el) {
      el.addEventListener('mouseover', onMouseOverChildren);
      el.addEventListener('mouseleave', onMouseLeaveChildren);
      document.addEventListener('scroll', onMouseLeaveChildren);

      return () => {
        el.removeEventListener('mouseover', onMouseOverChildren);
        el.removeEventListener('mouseleave', onMouseLeaveChildren);
        document.removeEventListener('scroll', onMouseLeaveChildren);
      };
    }
  }, [childrenRef]);

  return (
    <>
      {React.cloneElement(children, { ...children.props, ref: childrenRef })}
      <Portal isOpened={isChildrenMouseOver}>
        <TooltipContent
          className={className}
          controlElement={childrenRef.current}
          customContent={customContent}
          desc={desc}
          isFixed={isFixed}
          theme={theme}
          title={title}
          variant={variant}
        />
      </Portal>
    </>
  );
}

Tooltip.themes = Theme;
Tooltip.variants = Variant;
