const Metastore = ({ className }: { className?: string }) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M35.334 28.6666C35.334 32.3485 28.6183 35.3333 20.334 35.3333C12.0497 35.3333 5.33398 32.3485 5.33398 28.6666M35.334 12C35.334 15.6819 28.6183 18.6666 20.334 18.6666C12.0497 18.6666 5.33398 15.6819 5.33398 12C5.33398 8.31808 12.0497 5.33331 20.334 5.33331C28.6183 5.33331 35.334 8.31808 35.334 12Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M15.8838 31.6958L15.8838 22.3549L17.1551 22.3549L19.6979 29.4977L20.9692 29.4977L23.5119 22.3549L24.7833 22.3549L24.7833 31.6958"
        stroke="#26D07C"
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Metastore };
