import { useEffect, useState } from 'react';

enum breakpoints {
  CAROUSEL_NAVIGATION = 1340,
  XLG = 1280,
  LG = 1052,
  MD = 828,
  SM = 636,
  XSM = 435,
}

export const useWindowWidth = (options?: { defaultInnerWidth: boolean }) => {
  let stateVariant = undefined;

  if (options?.defaultInnerWidth)
    stateVariant =
      typeof window !== 'undefined' ? window.innerWidth : undefined;

  const [windowWidth, setWindowWidth] = useState<number | undefined>(
    stateVariant,
  );

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    handleResize();
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowWidth, breakpoints: breakpoints };
};
