const ArrowDownIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M20 25.625L10 15.625L11.9722 13.6528L20 21.7084L28.0278 13.6806L30 15.6528L20 25.625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { ArrowDownIcon };
