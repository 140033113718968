import cn from 'classnames';
import React from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { ClickAnalyticsType } from 'src/types';

import s from './Button.module.scss';

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'primary' | 'outline' | 'link';
  fontSize?: 'default' | 'large';
  loading?: boolean;
  className?: string;
  children: React.ReactNode;
  analyticsOptions: ClickAnalyticsType;
}

/**
 * Universal Button component
 */
export const Button = React.forwardRef<HTMLSpanElement, ButtonProps>(
  (
    {
      onClick,
      variant,
      loading,
      className = '',
      fontSize = 'default',
      children,
      analyticsOptions,
      ...rest
    },
    ref,
  ) => {
    const { clickAnalytics } = useAnalytics();

    return (
      <span
        ref={ref}
        className={cn(
          s.root,
          s.default,
          s.primary,
          'c-button c-button_primary',
          className,
          {
            [s.outline]: variant === 'outline',
            [s.primary]: variant === 'primary',
            [s.link]: variant === 'link',
            [s.large]: fontSize === 'large',
            [s.loading]: loading,
          },
        )}
        data-click="allclicks"
        data-qa="old-button"
        onClick={() => {
          if (!loading && onClick)
            try {
              clickAnalytics(analyticsOptions);
              // @ts-ignore
              onClick();
            } catch {}
        }}
        {...rest}
      >
        {!loading ? children : ''}
      </span>
    );
  },
);

Button.displayName = 'Button';
