const CloudBalance = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3233 29.633L21.7568 29.5603V29.6348H8.91605L8.90713 29.6348C8.89579 29.6349 8.88431 29.635 8.87269 29.635C5.80902 29.635 2.76254 26.8478 2.76254 23.7085C2.76254 20.8675 5.26919 18.258 8.12565 17.8376L9.98285 17.5644L10.2357 15.7024C10.6496 12.6551 13.3365 10.2317 16.6667 10.2317C19.9968 10.2317 22.6837 12.6551 23.0976 15.7024L23.3505 17.5644L25.2077 17.8376C28.0642 18.2579 30.5709 20.8675 30.5709 23.7085C30.5709 26.8478 27.5244 29.635 24.4608 29.635C24.4147 29.635 24.3689 29.6343 24.3233 29.633ZM24.5131 32.1332C24.5652 32.1328 24.6174 32.1319 24.6695 32.1304C28.9033 32.0111 33.0667 28.2862 33.0667 23.7085C33.0667 19.4565 29.4746 15.9402 25.5706 15.3658C24.9854 11.0572 21.222 7.73334 16.6667 7.73334C12.1113 7.73334 8.34787 11.0572 7.76268 15.3658C3.85874 15.9403 0.266724 19.4565 0.266724 23.7085C0.266724 28.3614 4.56814 32.1333 8.87269 32.1333L8.88996 32.1333C8.90164 32.1333 8.9133 32.1332 8.92496 32.1332H24.2526V32.1304C24.3045 32.1319 24.3564 32.1328 24.4085 32.1332C24.4172 32.1332 24.426 32.1333 24.4348 32.1333C24.4434 32.1333 24.4521 32.1333 24.4608 32.1333C24.4782 32.1333 24.4956 32.1333 24.5131 32.1332Z"
        fill="#26D07C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6185 15.7024L29.836 17.3021C29.959 17.4024 30.0796 17.5058 30.1974 17.6122L31.7312 17.8376C34.5913 18.2579 37.1011 20.8675 37.1011 23.7085C37.1011 26.8478 34.0508 29.635 30.9833 29.635C30.9373 29.635 30.8914 29.6343 30.8457 29.633L30.3959 29.6203C28.8272 31.122 26.7385 32.0712 24.6325 32.1305C24.5831 32.1318 24.5336 32.1327 24.4842 32.1332H30.775V32.1304C30.8269 32.1319 30.8789 32.1328 30.931 32.1332C30.9435 32.1332 30.9559 32.1333 30.9684 32.1333C30.9734 32.1333 30.9784 32.1333 30.9833 32.1333C31.0008 32.1333 31.0183 32.1333 31.0357 32.1332C31.0879 32.1328 31.1402 32.1319 31.1924 32.1304C35.4314 32.0111 39.6 28.2862 39.6 23.7085C39.6 19.4565 36.0035 15.9402 32.0946 15.3658C31.5087 11.0572 27.7406 7.73334 23.1795 7.73334C22.0221 7.73334 20.9158 7.94738 19.8995 8.33723C21.0296 8.77072 22.0482 9.42161 22.9018 10.2373C22.9938 10.2336 23.0864 10.2317 23.1795 10.2317C26.5138 10.2317 29.204 12.6551 29.6185 15.7024ZM24.2151 32.1305C24.2642 32.1318 24.3135 32.1327 24.3628 32.1332H24.2151V32.1305ZM15.3334 32.1332H15.4181L15.4129 32.1332L15.3931 32.1333L15.3758 32.1333C15.3617 32.1333 15.3475 32.1333 15.3334 32.1332Z"
        fill="#CCCBCA"
      />
      <path
        d="M23.4667 23.4667C23.4667 24.203 22.8698 24.8 22.1334 24.8C21.397 24.8 20.8 24.203 20.8 23.4667C20.8 22.7303 21.397 22.1333 22.1334 22.1333C22.8698 22.1333 23.4667 22.7303 23.4667 23.4667Z"
        fill="#343F48"
      />
      <mask id="path-4-inside-1_804_725" fill="white">
        <path d="M7.73328 23.4667C7.73328 21.2575 9.52414 19.4667 11.7333 19.4667H22.2666C24.4757 19.4667 26.2666 21.2575 26.2666 23.4667C26.2666 25.6758 24.4757 27.4667 22.2666 27.4667H11.7333C9.52414 27.4667 7.73328 25.6758 7.73328 23.4667Z" />
      </mask>
      <path
        d="M19.7333 23.4667C19.7333 27.885 16.1516 31.4667 11.7333 31.4667V7.46667C2.89672 7.46667 -4.26672 14.6301 -4.26672 23.4667H19.7333ZM11.7333 31.4667H22.2666V7.46667H11.7333V31.4667ZM22.2666 31.4667C17.8483 31.4667 14.2666 27.885 14.2666 23.4667H38.2666C38.2666 14.6301 31.1032 7.46667 22.2666 7.46667V31.4667ZM14.2666 23.4667C14.2666 19.0484 17.8483 15.4667 22.2666 15.4667V39.4667C31.1032 39.4667 38.2666 32.3032 38.2666 23.4667H14.2666ZM22.2666 15.4667H11.7333V39.4667H22.2666V15.4667ZM11.7333 15.4667C16.1516 15.4667 19.7333 19.0484 19.7333 23.4667H-4.26672C-4.26672 32.3032 2.89672 39.4667 11.7333 39.4667V15.4667Z"
        fill="#343F48"
        mask="url(#path-4-inside-1_804_725)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 24.2667H11.4667V22.6667H20V24.2667Z"
        fill="#343F48"
      />
    </svg>
  );
};

export { CloudBalance };
